import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";

import moment from "moment";
import { api, header_token, admin_status } from "../../config";
import Axios from "axios";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";

import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { RulesContext } from "../../store/keep";

export const SettingAdmin = () => {
  const { setrules } = useContext(RulesContext);

  const closeAdd = useRef(null);
  const closeEdit = useRef(null);
  const closePass = useRef(null);

  const { register, handleSubmit, errors } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
  } = useForm();

  const [picture, setpicture] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [full_name, setfull_name] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);

  const [register_date, setregister_date] = useState(new Date());
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [select_rules, setselect_rules] = useState({
    banner: false,
    branch: false,
    deposit: false,
    member: false,
    news: false,
    order: false,
    promotion: false,
    promotion_code: false,
    recommend: false,
    restaurant: false,
    rider: false,
    setting: false,
    shipping: false,
    summary: false,
    withdraw: false,
    service: false,
    service_setting: false,
    user_service: false,
  });

  const [is_create, setis_create] = useState(true);

  const [user_id, setuser_id] = useState([]);

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const adminRegister = async () => {
    try {
      let momentObj = moment(register_date);
      var register = momentObj.format("YYYY-MM-DD");

      let send_data = {
        full_name: full_name,
        email: email,
        provider: {
          password: password,
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: username,
        },
        register_date: register,
        status: parseInt(status.value),
      };

      let res = await Axios.post(
        `${api}/admin/user/create-admin`,
        send_data,
        header_token
      );

      // console.log(res.data);
      let rule_api = await Axios.post(
        `${api}/admin/rule/${res.data.id}`,
        select_rules,
        header_token
      );
      // console.log("create", rule_api);

      // console.log(res);
      // if (res.status === 201) {
      if (res.status === 201 && rule_api.status === 201) {
        closeAdd.current.click();
        getData();
        swal("สร้างแอดมินเสร็จสิ้น !!", {
          icon: "success",
        });
        setuser_id("");
        setusername("");
        setpassword("");
        setconfirm_password("");
        setemail("");
        setfull_name("");
        setregister_date(new Date());
        setstatus({ value: 1, label: "ใช้งาน" });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editAdmin = async () => {
    try {
      let momentObj = moment(register_date);
      var register = momentObj.format("YYYY-MM-DD");

      let send_data = {
        full_name: full_name,
        email: email,
        register_date: register,
        status: parseInt(status.value),
        picture: picture,
      };

      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/user/profile/${user_id}`,
        send_data,
        header_token
      );

      // console.log("select_rules", select_rules);
      let rule_api;
      if (is_create) {
        rule_api = await Axios.put(
          `${api}/admin/rule/${user_id}`,
          select_rules,
          header_token
        );
        // console.log("edit", rule_api);
      } else {
        rule_api = await Axios.post(
          `${api}/admin/rule/${user_id}`,
          select_rules,
          header_token
        );
        // console.log("create", rule_api);
      }

      // console.log(res);
      if (
        res.status === 200 &&
        (rule_api.status === 200 || rule_api.status === 201)
      ) {
        closeEdit.current.click();

        if (user_id === localStorage.getItem("user_id")) {
          let tmp = { ...rule_api.data };
          delete tmp["id"];
          delete tmp["user_id"];
          // console.log("tmp", tmp);

          setrules(tmp);
        }

        getData();
        setuser_id("");
        setpicture("");
        setusername("");
        setpassword("");
        setconfirm_password("");
        setemail("");
        setfull_name("");
        setregister_date(new Date());
        setstatus({ value: 1, label: "ใช้งาน" });
        swal("แก้ไขแอดมินเสร็จสิ้น !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteAdmin = async (id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/user/profile/${id}`,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        // console.log("okkk");
        getData();
        swal("ลบเสร็จสิ้น !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      // console.log(;
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        status: -1,
        // full_name: "",
        role: "admin",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);

      setdata(data.users);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const getRule = async (id) => {
    try {
      let { data } = await Axios.get(`${api}/admin/rule/${id}`, header_token);
      // console.log("data", data);

      let tmp = { ...data };
      delete tmp["id"];
      delete tmp["user_id"];
      // console.log("tmp", tmp);
      setselect_rules(tmp);
      setis_create(true);
    } catch (error) {
      console.log(error.response);
      setis_create(false);
      setselect_rules({
        banner: false,
        branch: false,
        deposit: false,
        member: false,
        news: false,
        order: false,
        promotion: false,
        recommend: false,
        restaurant: false,
        rider: false,
        setting: false,
        shipping: false,
        summary: false,
        withdraw: false,
        service: false,
        service_setting: false,
      });
    }
  };

  const onSubmit = () => {
    if (password === confirm_password) {
      adminRegister();
    } else {
      alert("รหัสผ่านไม่เหมือนกัน");
    }
  };

  const onSubmitEdit = () => {
    editAdmin();
  };

  const onEditPassword = async (data) => {
    // console.log("error");
    if (password === confirm_password) {
      try {
        let send_data = {
          password: password,
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: username,
        };
        // console.log("send data", send_data);

        let pass = await Axios.put(
          `${api}/admin/user/change-password/${user_id}`,
          send_data,
          header_token
        );
        // console.log("pass", pass);

        if (pass.status === 200) {
          getData();
          setuser_id("");
          setusername("");
          setpassword("");
          setconfirm_password("");
          swal("แก้ไขรหัสผ่านเสร็จสิ้น !!", {
            icon: "success",
            timer: 1500,
          });
          closePass.current.click();
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="mb-3 mt-2">ผู้ดูแลระบบ</h4>
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-12 border-bottom mb-3">
                  <div className="row mb-2">
                    <button
                      data-toggle="modal"
                      data-target=".add-admin"
                      className="btn btn-outline-primary btn-sm ml-auto mr-2"
                      onClick={() => {
                        setselect_rules({
                          banner: false,
                          deposit: false,
                          member: false,
                          news: false,
                          order: false,
                          promotion: false,
                          recommend: false,
                          restaurant: false,
                          rider: false,
                          setting: false,
                          shipping: false,
                          summary: false,
                          withdraw: false,
                        });
                        setuser_id("");
                        setusername("");
                        setpassword("");
                        setconfirm_password("");
                        setemail("");
                        setfull_name("");
                        setregister_date(new Date());
                        setstatus({ value: 1, label: "ใช้งาน" });
                      }}
                    >
                      + เพิ่มผู้ดูแล
                    </button>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <LoadingMask loading={loading} text={"loading..."}>
                        <div style={{ minHeight: 500 }}>
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th width="100"> ตำแหน่ง</th>
                                  <th width="200">วันที่</th>
                                  <th width="150">สถานะ</th>
                                  <th width="340">หมายเหตุ</th>
                                  <th width="300">ดำเนินการ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>Admin</td>
                                      <td>
                                        {moment(value.register_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {(() => {
                                          switch (value.status) {
                                            case 0:
                                              return (
                                                <div className="badge badge-warning">
                                                  ระงับ
                                                </div>
                                              );
                                            case 1:
                                              return (
                                                <div className="badge badge-success">
                                                  ใช้งาน
                                                </div>
                                              );

                                            case 2:
                                              return (
                                                <div className="badge badge-danger">
                                                  ไม่อนุมัติ
                                                </div>
                                              );
                                            case 3:
                                              return (
                                                <div className="badge badge-primary">
                                                  รออนุมัติ
                                                </div>
                                              );

                                            default:
                                              break;
                                          }
                                        })()}
                                      </td>
                                      <td>{value.full_name}</td>
                                      <td>
                                        <button
                                          className="btn btn-outline-primary btn-sm ml-auto"
                                          onClick={() => {
                                            swal({
                                              title: `ต้องการลบแอดมินหรือไม่ ?`,
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                deleteAdmin(value.id);
                                              }
                                            });
                                          }}
                                        >
                                          ลบ
                                        </button>
                                        <button
                                          data-toggle="modal"
                                          data-target={`.edit-admin`}
                                          className="btn btn-outline-primary btn-sm ml-2"
                                          onClick={() => {
                                            getRule(value.id);
                                            setpicture(value.picture);
                                            setuser_id(value.id);
                                            setusername(value.username);
                                            // setpassword("");
                                            // setconfirm_password("");
                                            setemail(value.email);
                                            setfull_name(value.full_name);
                                            setregister_date(
                                              new Date(value.register_date)
                                            );
                                            setstatus(
                                              admin_status.filter(
                                                (e) =>
                                                  parseInt(e.value) ===
                                                  parseInt(value.status)
                                              )[0]
                                            );
                                          }}
                                        >
                                          แก้ไข
                                        </button>

                                        <button
                                          data-toggle="modal"
                                          data-target={`.edit-password`}
                                          className="btn btn-outline-primary btn-sm ml-2"
                                          onClick={() => {
                                            setuser_id(value.id);
                                            setusername(value.username);
                                            setpassword("");
                                            setconfirm_password("");
                                          }}
                                        >
                                          แก้ไขรหัสผ่าน
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </LoadingMask>
                      <div className="mt-2" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"← Previous"}
                          nextLabel={"Next →"}
                          breakLabel={<span className="gap">...</span>}
                          pageCount={page_count}
                          onPageChange={(e) => setcurrent_page(e.selected + 1)}
                          forcePage={current_page - 1}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previous_page"}
                          nextLinkClassName={"next_page"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal  edit-password" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <span>แก้ไขรหัสผ่านผู้ดูแล</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="123" onSubmit={handleSubmit2(onEditPassword)}>
                <div className="row mb-4">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="exampleInputEmail2"
                        className="col-sm-3 col-form-label"
                      >
                        Username
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="username3"
                          className={`form-control`}
                          name="username3"
                          type="text"
                          disabled
                          value={username}
                          // onChange={(e) => {
                          //   // setusername(e.target.value);
                          // }}
                        />
                      </div>
                    </div>

                    {password !== confirm_password ? (
                      <div className=" row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-5">
                          <label htmlFor="name" style={{ color: "red" }}>
                            รหัสผ่านไม่ตรงกัน
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="password">Password</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="password2"
                          className={`form-control ${
                            errors2.password ? "error-input" : ""
                          }`}
                          name="password2"
                          type={show ? "text" : "password"}
                          ref={register2({ required: true })}
                          value={password}
                          onChange={async (e) => {
                            await setpassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="confirm_password">
                          Confirm password
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          className={`form-control`}
                          // name="confirm_password"
                          type={show ? "text" : "password"}
                          // ref={register({ required: true })}
                          value={confirm_password}
                          onChange={async (e) => {
                            await setconfirm_password(e.target.value);
                          }}
                        />
                        <div className="form-check mt-4 ml-4">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            checked={show}
                            onChange={() => setshow(!show)}
                          />
                          Show Password
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closePass}
                        type="button"
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal  add-admin" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มผู้ดูแลระบบ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-4">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="name">ชื่อผู้ใช้</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="username2"
                          className={`form-control ${
                            errors.username ? "error-input" : ""
                          }`}
                          ref={register({
                            required: true,
                          })}
                          name="username2"
                          type="text"
                          value={username}
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="full_name">หมายเหตุ</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="full_name"
                          className={`form-control ${
                            errors.full_name ? "error-input" : ""
                          }`}
                          ref={register({
                            required: true,
                          })}
                          name="full_name"
                          type="text"
                          value={full_name}
                          onChange={(e) => setfull_name(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="name">อีเมล์</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="email"
                          className={`form-control ${
                            errors.email ? "error-input" : ""
                          }`}
                          name="email"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                    </div>

                    {password !== confirm_password ? (
                      <div className=" row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-5">
                          <label htmlFor="name" style={{ color: "red" }}>
                            รหัสผ่านไม่ตรงกัน
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="password">Password</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="password"
                          className={`form-control ${
                            errors.password ? "error-input" : ""
                          }`}
                          name="password"
                          type={show ? "text" : "password"}
                          ref={register({ required: true })}
                          value={password}
                          onChange={async (e) => {
                            await setpassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="confirm_password">
                          Confirm password
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="confirm_password"
                          className={`form-control ${
                            errors.confirm_password ? "error-input" : ""
                          }`}
                          name="confirm_password"
                          type={show ? "text" : "password"}
                          // ref={register({ required: true })}
                          value={confirm_password}
                          onChange={async (e) => {
                            await setconfirm_password(e.target.value);
                          }}
                        />
                        <div className="form-check mt-4 ml-4">
                          <input
                            type="checkbox"
                            className="form-check-input "
                            checked={show}
                            onChange={() => setshow(!show)}
                          />
                          Show Password
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="address">วันที่สมัคร</label>
                      </div>
                      <div className="col-lg-8">
                        <DayPickerInput
                          value={register_date}
                          onDayChange={(e) => {
                            setregister_date(e);
                          }}
                          placeholder={"วัน/เดือน/ปี"}
                          style={{ width: "100%" }}
                          inputProps={{
                            style: { width: "100%", padding: 5 },
                          }}
                          format="L"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            locale: "th",
                            localeUtils: MomentLocaleUtils,
                          }}
                          readonly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="status">สถานะ</label>
                      </div>
                      <div className="col-lg-8">
                        <Select
                          id="status"
                          name="status"
                          options={admin_status}
                          placeholder={"สถานะ"}
                          value={status}
                          onChange={(e) => setstatus(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <h4 className="mb-3 mt-2">กำหนดสิทธิ์การใช้งาน</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <th width="20">
                              <input
                                type="checkbox"
                                className="selectAll "
                                name="selectAll"
                                defaultChecked={false}
                                onClick={(e) => {
                                  setselect_rules({
                                    branch: e.target.checked,
                                    banner: e.target.checked,
                                    deposit: e.target.checked,
                                    member: e.target.checked,
                                    news: e.target.checked,
                                    order: e.target.checked,
                                    promotion: e.target.checked,
                                    promotion_code: e.target.checked,
                                    recommend: e.target.checked,
                                    restaurant: e.target.checked,
                                    rider: e.target.checked,
                                    setting: e.target.checked,
                                    // shipping: e.target.checked,
                                    summary: e.target.checked,
                                    withdraw: e.target.checked,
                                    car_service: e.target.checked,
                                    car_service_setting: e.target.checked,
                                  });
                                }}
                              />
                            </th>
                            <th>สิทธิ์การเข้าใช้งาน</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.member || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.member = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>สมาชิก</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.rider || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.rider = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>พนักงานส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.restaurant || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.restaurant = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ร้านอาหาร</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.order || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.order = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>คำสั่งซื้อ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.car_service || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.car_service = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>เรียกรถ</td>
                          </tr>

                          {/* <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.shipping || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.shipping = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>การจัดส่ง</td>
                          </tr> */}

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.summary || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.summary = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ยอดขาย/ค่าบริการ</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.promotion || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.promotion = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นค่าส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.deposit || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.deposit = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>เติมเครดิต</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.withdraw || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.withdraw = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ถอนเงิน</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.banner || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.banner = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นแบนเนอร์</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.recommend || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.recommend = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ร้านแนะนำ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.news || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.news = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ประกาศ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.branch || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.branch = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>สาขา</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.promotion_code || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.promotion_code = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นโค้ด</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.car_service_setting || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.car_service_setting = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>บริการขนส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.setting || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.setting = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ตั้งค่า</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeAdd}
                        type="button"
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal  edit-admin" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มผู้ดูแลระบบ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit3(onSubmitEdit)}>
                <div className="row mb-4">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="name">ชื่อผู้ใช้</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="username"
                          className={`form-control ${
                            errors3.username ? "error-input" : ""
                          }`}
                          ref={register3({
                            required: true,
                          })}
                          name="username"
                          type="text"
                          value={username}
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="full_name">หมายเหตุ</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="full_name2"
                          className={`form-control ${
                            errors3.full_name ? "error-input" : ""
                          }`}
                          ref={register3({
                            required: true,
                          })}
                          name="full_name2"
                          type="text"
                          value={full_name}
                          onChange={(e) => setfull_name(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="name">อีเมล์</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          id="email2"
                          className={`form-control ${
                            errors3.email ? "error-input" : ""
                          }`}
                          name="email2"
                          type="text"
                          ref={register3({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="address">วันที่สมัคร</label>
                      </div>
                      <div className="col-lg-8">
                        <DayPickerInput
                          value={register_date}
                          onDayChange={(e) => {
                            setregister_date(e);
                          }}
                          placeholder={"วัน/เดือน/ปี"}
                          style={{ width: "100%" }}
                          inputProps={{
                            style: { width: "100%", padding: 5 },
                          }}
                          format="L"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            locale: "th",
                            localeUtils: MomentLocaleUtils,
                          }}
                          readonly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="status">สถานะ</label>
                      </div>
                      <div className="col-lg-8">
                        <Select
                          id="status"
                          name="status"
                          options={admin_status}
                          placeholder={"สถานะ"}
                          value={status}
                          onChange={(e) => setstatus(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <h4 className="mb-3 mt-2">กำหนดสิทธิ์การใช้งาน</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <th width="20">
                              <input
                                type="checkbox"
                                className="selectAll "
                                name="selectAll"
                                defaultChecked={false}
                                onClick={(e) => {
                                  setselect_rules({
                                    branch: e.target.checked,
                                    banner: e.target.checked,
                                    deposit: e.target.checked,
                                    member: e.target.checked,
                                    news: e.target.checked,
                                    order: e.target.checked,
                                    promotion: e.target.checked,
                                    promotion_code: e.target.checked,
                                    recommend: e.target.checked,
                                    restaurant: e.target.checked,
                                    rider: e.target.checked,
                                    setting: e.target.checked,
                                    // shipping: e.target.checked,
                                    summary: e.target.checked,
                                    withdraw: e.target.checked,
                                    car_service: e.target.checked,
                                    car_service_setting: e.target.checked,
                                  });
                                }}
                              />
                            </th>
                            <th>สิทธิ์การเข้าใช้งาน</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.member || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.member = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>สมาชิก</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.rider || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.rider = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>พนักงานส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.restaurant || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.restaurant = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ร้านอาหาร</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.order || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.order = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>คำสั่งซื้อ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.car_service || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.car_service = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>เรียกรถ</td>
                          </tr>

                          {/* <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.shipping || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.shipping = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>การจัดส่ง</td>
                          </tr> */}
                          
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.summary || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.summary = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ยอดขาย/ค่าบริการ</td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.promotion || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.promotion = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นค่าส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.deposit || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.deposit = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>เติมเครดิต</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.withdraw || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.withdraw = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ถอนเงิน</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.banner || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.banner = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นแบนเนอร์</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.recommend || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.recommend = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ร้านแนะนำ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.news || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.news = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ประกาศ</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.branch || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.branch = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>สาขา</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.promotion_code || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.promotion_code = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>โปรโมชั่นโค้ด</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.car_service_setting || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.car_service_setting = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>บริการขนส่ง</td>
                          </tr>

                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={select_rules.setting || ""}
                                onChange={(e) => {
                                  let tmp = { ...select_rules };
                                  tmp.setting = e.target.checked;
                                  setselect_rules(tmp);
                                }}
                              />
                            </td>
                            <td>ตั้งค่า</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeEdit}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
